














































import Vue from 'vue'
import AccordionButton from './accordion-button.vue'
import AccordionCard from './accordion-card.vue'
import JSXRenderer from '~/components/commons/jsx-renderer'

export default Vue.extend({
  name: 'Accordion',
  components: {
    AccordionButton,
    AccordionCard,
    JSXRenderer
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    accordionModel: {
      type: String,
      required: false,
      default: () => 'buttonModel'
    },
    modelKind: {
      type: String,
      required: false,
      default: null
    },
    gototop: {
      type: String,
      required: false,
      default: 'no'
    },
    defaultIndex: {
      type: Number,
      required: false,
      default: null
    }
  },
  data(): {
    activeIndex: number | null
  } {
    return {
      activeIndex: this.defaultIndex
    }
  },
  computed: {
    isOpened(): boolean {
      return this.activeIndex !== null
    }
  },
  methods: {
    toggle(newIndex: number): void {
      if (this.activeIndex === newIndex) {
        this.collapseAll()
      } else {
        this.activeIndex = newIndex
      }

      this.$emit(
        'toggle-item',
        this.activeIndex === null ? 'close' : 'open',
        this.items[newIndex]
      )
    },
    collapseAll(): void {
      this.activeIndex = null
    }
  }
})
